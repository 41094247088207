/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Buffer } from 'buffer';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import {
  AuthFeature,
  AuthFeatureState
} from '../../../../ecomm/store/features/auth';
import {
  Customer,
  CustomerDeliveryAddress
} from '../../../../ecomm/types/customer.types';
import {
  DeliveryLocationResponseData,
  LocationsResponseData,
  LocationStatus,
} from '../../../../ecomm/types/search-location.types';
import {
  HandoffMode,
  SelectedHandoffDeliveryAddress
} from '../../../../ecomm/types/selected-handoff-mode.types';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { SearchLocationWorkflowService } from '../../../../ecomm/workflows/search-location/search-location-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import {
  MapSearchEvent,
  MarkerData,
  PartialOutageModalComponent,
  Tab
} from '../../../common';
import { LocationSearchNavigateEvent } from '../location-search-card/location-search-card.component';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import {
  CONFIG,
  Config
} from '../../../../ecomm/providers/config/config.provider';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RegexPatterns } from '../../../../ecomm/constants/regex-patterns';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';

@Component({
  selector: 'wri-choose-location',
  templateUrl: './choose-location.component.html',
  styleUrls: ['./choose-location.component.scss']
})
export class ChooseLocationComponent
  implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy
{
  private static readonly STORES_TO_REDIRECT_TO_LEGACY: string[] = [
    LocationStatus.Legacy
  ];
  selectedHandoffMode = 'carryout';
  deliveryAddressData = {
    isNew: true,
    address: null as CustomerDeliveryAddress | null
  };
  searching = false;
  locationSearching = false;
  searchText: string | null = '';
  secondaryAddressSearchText: string | null = '';
  placeholderText: string | null = '';
  deliveryPlaceholderText = 'e.g. 2593 Pine St. San Francisco, CA 94111';
  selectedDeliveryAddress: NgbTypeaheadSelectItemEvent | undefined;
  nearbyLocationsList: LocationsResponseData | null | undefined;
  recentLocationsList: LocationsResponseData | null | undefined;
  favoriteLocationsList: LocationsResponseData | null | undefined;
  deliveryLocation: DeliveryLocationResponseData | null | undefined;
  userDetails: Customer | null = null;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  productRouteParam: string | null | undefined;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild('switchLocationConfirmationModal')
  switchLocationConfirmationModal!: TemplateRef<HTMLElement>;
  public locationTabs: Tab[] = [
    { id: 'nearby', default: true, display: 'nearby' },
    ...(this.featureFlagService.featureFlags.enableRecentLocationsTab
      ? [{ id: 'recent', display: 'recent' }]
      : []),
    ...(this.featureFlagService.featureFlags.enableFavoriteLocationsTab
      ? [{ id: 'favorites', display: 'favorites' }]
      : [])
  ];
  public storeInfo: StoreInfo | null = null;
  public cartState: CartFeatureState | undefined;
  public selectedTab$ = new BehaviorSubject<Tab>(this.locationTabs[0]);
  selectedTabId = this.locationTabs[0].id;
  showMapPreview = true;
  mapSearchEnabled = true;
  showPrimaryAddressError = false;
  showPrimaryAddressNoSelectedAddressError = false;
  showSecondaryAddressError = false;
  selectedMarker: string | null = null;
  mapLocations$: Subject<MarkerData[]> = new BehaviorSubject<MarkerData[]>([]);
  isLoading = false;
  public useMyLocationIsSupported = false;
  @ViewChild('inputElem', { static: true }) inputElem: ElementRef | undefined;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(CONFIG) private config: Config,
    private analyticsService: AnalyticsService,
    private searchLocationWorkflowService: SearchLocationWorkflowService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private userAccountService: CustomerWorkflowService,
    private storeInfoService: StoreInfoWorkflowService,
    private featureFlagService: FeatureFlagService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private redirectService: RedirectService,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    private authService: AuthService
  ) {}

  _showMobileMap = false;

  get showMobileMap() {
    return this._showMobileMap && this.selectedHandoffMode !== 'delivery';
  }

  set showMobileMap(val: boolean) {
    this._showMobileMap = val;
  }

  get isSearchUsingDeviceLocationEnabled() {
    return this.featureFlagService.featureFlags.enableSearchUsingDeviceLocation;
  }

  get isRecentLocationsEnabled() {
    return this.featureFlagService.featureFlags.enableRecentLocationsTab;
  }

  get isFavoriteLocationsTabEnabled() {
    return this.featureFlagService.featureFlags.enableFavoriteLocationsTab;
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.queryParamMap.get('searchQuery') ?? '') {
      this.searchText = decodeURIComponent(
        this.route.snapshot.queryParamMap.get('searchQuery') ?? ''
      );
      this.inputElem?.nativeElement.dispatchEvent(new Event('input'));
      this.inputElem?.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async onMapSearch($event: MapSearchEvent) {
    this.searching = false;
    this.locationSearching = true;

    this.nearbyLocationsList =
      await this.searchLocationWorkflowService.getLocationsByCarryout({
        latitude: $event.lat,
        longitude: $event.lng,
        radius: Math.min(Math.max($event.radius, 1), 100)
      });
    this.setLocations(this.nearbyLocationsList);
    const nearbyTab = this.locationTabs.find((t) => t.id === 'nearby');
    if (nearbyTab) {
      this.selectedTab$.next(nearbyTab);
    }
    this.searchText = null;
    this.placeholderText = 'Map Region';
    this.locationSearching = false;
  }

  onMapStartSearch($event: boolean) {
    this.showMapPreview = !$event;
  }

  onMapMarkerSelect($event: string | null) {
    this.selectedMarker = $event;
  }

  onMobileMapViewToggle() {
    this.showMobileMap = !this._showMobileMap;
    if (this.showMobileMap) {
      this.analyticsService.logGaEvent({ event: 'store_locator_map_view' });
    }
  }

  async redirectTo(redirectUrl: string) {
    if (this.featureFlagService.featureFlags['enableEatStoreMenuUrl']) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions['feature_enable_eat_store_menu_url']?.value ===
          'false'
      ) {
        this.redirectService.redirectToLegacyStoreMenuUrl(redirectUrl, false);
      } else {
        this.redirectService.redirectToLegacyStoreMenuUrl(redirectUrl);
      }
    } else {
      this.redirectService.redirectToLegacyStoreMenuUrl(redirectUrl, false);
    }
  }

  onLocationNavigate($event: LocationSearchNavigateEvent) {
    const url = `location/${$event.slug}/menu`;
    if (
      ChooseLocationComponent.STORES_TO_REDIRECT_TO_LEGACY.includes(
        $event.locationStatus
      )
    ) {
      // for a delivery order
      if ($event.deliveryAddress) {
        const redirectUrl = `${url}?handoffMode=${this.selectedHandoffMode}&deliveryAddress=${$event.deliveryAddress}&ngfe_order`;
        this.redirectTo(redirectUrl);
      } else {
        const redirectUrl = `${url}?ngfe_order`;
        this.redirectTo(redirectUrl);
      }
    } else {
      this.router.navigate([url], {
        queryParams: {
          handoffMode: this.selectedHandoffMode,
          ...(this.productRouteParam
            ? { product: this.productRouteParam }
            : {}),
          ...($event.deliveryAddress
            ? { deliveryAddress: $event.deliveryAddress }
            : {})
        },
        state: { fromOrderPage: true }
      });
    }
  }

  async onLoginNavigate() {
    const encodeUrlPath = encodeURIComponent(this.router.url);

    if (this.featureFlagService.featureFlags['enableLogin']) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions['feature_enable_login']?.value === 'false'
      ) {
        this.redirectService.redirectToLegacy(
          `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
        );
      } else {
        await this.authService.login(this.router.url);
      }
    } else {
      this.redirectService.redirectToLegacy(
        `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
      );
    }
  }

  async onSelectSavedDeliveryAdress($event: CustomerDeliveryAddress) {
    if (
      this.cartState?.cart?.handoffMode === HandoffMode.carryout &&
      this.isCartNotEmpty()
    ) {
      this.deliveryAddressData = {
        isNew: false,
        address: $event
      };
      this.modalService.open(this.switchLocationConfirmationModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      });
    } else {
      await this.navigateToMenuForSavedDeliveryAddress($event);
    }
  }

  async navigateToMenuForSavedDeliveryAddress($event: CustomerDeliveryAddress) {
    const deliveryLocation = await this.fetchDeliveryLocation({
      item: {
        latitude: $event.latitude || 0,
        longitude: $event.longitude || 0,
        address: {
          streetAddress: $event.streetAddress,
          locality: $event.locality,
          region: $event.region,
          postalCode: $event.postalCode,
          countryCode: $event.countryCode
        }
      }
    });

    if (deliveryLocation) {
      const deliveryAddressForActiveStore: SelectedHandoffDeliveryAddress = {
        streetAddress: $event.streetAddress || '',
        postalCode: $event.postalCode || '',
        country: $event.countryCode || '',
        latitude: $event.latitude || 0,
        longitude: $event.longitude || 0,
        secondaryAddress: $event.secondaryStreetAddress || '',
        locality: $event.locality,
        region: $event.region,
        deliveryInstructions: $event.deliveryInstructions || ''
      };
      const deliveryAddressForActiveStoreStr = JSON.stringify(
        deliveryAddressForActiveStore
      );

      const deliveryAddressForInactiveStore: SelectedHandoffDeliveryAddress = {
        streetAddress: $event.streetAddress || '',
        postalCode: $event.postalCode || '',
        country: $event.countryCode || '',
        latitude: $event.latitude || 0,
        longitude: $event.longitude || 0,
        city: $event.locality,
        state: $event.region
      };
      const deliveryAddressForInactiveStoreStr = JSON.stringify(
        deliveryAddressForInactiveStore
      );

      const deliveryAddressB64 =
        ChooseLocationComponent.STORES_TO_REDIRECT_TO_LEGACY.includes(
          $event.locationStatus || ''
        )
          ? Buffer.from(deliveryAddressForInactiveStoreStr).toString('base64')
          : Buffer.from(deliveryAddressForActiveStoreStr).toString('base64');

      this.onLocationNavigate({
        locationStatus: deliveryLocation?.locationStatus ?? '',
        slug: deliveryLocation?.slug,
        handoffMode: 'delivery',
        deliveryAddress: deliveryAddressB64
      });
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.getParamsFromRoute();

    this.subscribeToAuthState();
    this.subscribeToStoreInfoState();
    this.subscribeToCartState();
    this.subscribeToRegionalConfigState();

    const regionalConfig =
      await this.storeInfoService.getRegionalConfigurationAndSave('');
    // redirect to legacy
    if (
      regionalConfig?.regionalConfigurationOptions['feature_enable_order_route']
        ?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy('order');
    }
    if (this.isSearchUsingDeviceLocationEnabled && navigator.geolocation) {
      this.useMyLocationIsSupported = true;
    }

    this.setMetadata();

    this.selectedTab$.subscribe((tab) => {
      this.selectedTabId = tab.id;
      if (this.showRecentLocations()) this.fetchRecentLocations();
      if (this.showFavoriteLocationsTab()) this.fetchFavoriteLocations();
      if (this.showNearbyTab()) {
        this.setLocations(this.nearbyLocationsList ?? { locations: [] });
      }
    });

    this.attemptNearbyCarryoutSearch(this.selectedHandoffMode);
  }

  setMetadata() {
    this.seoService.setMetaData({
      title: this.productRouteParam
        ? `Order Our ${this.productRouteParam} at a Location Near You | Wingstop`
        : `Order Wings Online for ${this.selectedHandoffMode} | Wingstop`,
      description: this.productRouteParam
        ? this.selectedHandoffMode === 'carryout'
          ? `Placing an order for ${this.productRouteParam} at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now, available for carryout or delivery!`
          : `Placing a delivery order for ${this.productRouteParam} at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now!`
        : this.selectedHandoffMode === 'carryout'
        ? 'Placing an order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now, available for carryout or delivery!'
        : 'Placing a delivery order at your nearest Wingstop ahead of time is quick, easy, and delicious! Start your order now!'
    });
  }

  resultsFormatter = (x: any) => {
    return typeof x === 'string' ? x : x.name;
  };
  inputFormatter = (x: any) => {
    return typeof x === 'string' ? x : x.name;
  };

  async attemptNearbyCarryoutSearch(handoffMode: string) {
    if (handoffMode === 'carryout') {
      const browserPermission = await navigator.permissions?.query({
        name: 'geolocation'
      });
      if (browserPermission?.state === 'granted') {
        this.useMyCurrentLocation();
      }
    }
  }

  async onHandoffChange(handoffMode: string) {
    this.searchText = null;
    this.secondaryAddressSearchText = null;
    this.deliveryLocation = undefined;
    this.nearbyLocationsList = null;
    this.mapLocations$.next([]);
    this.selectedHandoffMode = handoffMode;
    this.showPrimaryAddressError = false;
    this.showSecondaryAddressError = false;
    this.showPrimaryAddressNoSelectedAddressError = false;

    this.attemptNearbyCarryoutSearch(handoffMode);

    this.analyticsService.logGaEvent({
      event: 'change_handoff',
      handoff_type:
        handoffMode === 'carryout' ? 'Change to Carryout' : 'Change to Delivery'
    });
  }

  async selectedItem(item: NgbTypeaheadSelectItemEvent) {
    this.searching = false;

    this.analyticsService.logGaEvent({
      event: 'search',
      order_method: this.selectedHandoffMode
    });

    this.nearbyLocationsList = null;
    this.locationSearching = true;
    if (this.selectedHandoffMode === 'carryout') {
      this.nearbyLocationsList = await this.fetchStoreLocations(
        item.item?.latitude,
        item.item?.longitude
      );

      if (
        this.nearbyLocationsList?.locations &&
        this.nearbyLocationsList?.locations.length > 0
      ) {
        this.analyticsService.logGaEvent({
          event: 'view_search_results'
        });
      }
      this.setLocations(this.nearbyLocationsList);
    } else {
      this.mapLocations$.next([]);
      this.selectedDeliveryAddress = item;
      this.showPrimaryAddressError = false;
      this.showPrimaryAddressNoSelectedAddressError = false;
    }

    this.locationSearching = false;
  }

  noItemSelected(event: { target: { value: any } }) {
    if (event.target.value !== '') {
      this.showPrimaryAddressError = false;
      this.showPrimaryAddressNoSelectedAddressError =
        this.selectedDeliveryAddress?.item.name !== event.target.value;
    } else {
      this.showPrimaryAddressNoSelectedAddressError = false;
    }
  }

  private isCartNotEmpty(): boolean | undefined {
    return this.cartState?.cart?.items && this.cartState.cart.items.length > 0;
  }

  continueForDeliveryBtnClick() {
    if (
      (document.getElementById('location-search-input') as HTMLInputElement)
        ?.value !== '' &&
      this.selectedDeliveryAddress !== undefined &&
      !this.showPrimaryAddressNoSelectedAddressError
    ) {
      this.showPrimaryAddressError = false;
      this.continueForDelivery();
    } else if (
      (document.getElementById('location-search-input') as HTMLInputElement)
        ?.value !== '' &&
      this.selectedDeliveryAddress !== undefined &&
      this.showPrimaryAddressNoSelectedAddressError
    ) {
      this.showPrimaryAddressError = false;
    } else if (
      (document.getElementById('location-search-input') as HTMLInputElement)
        ?.value !== '' &&
      this.selectedDeliveryAddress === undefined
    ) {
      this.showPrimaryAddressError = false;
    } else {
      this.showPrimaryAddressError = true;
    }
  }

  async continueForDelivery() {
    this.deliveryAddressData.isNew = true;
    if (
      this.cartState?.cart?.handoffMode === HandoffMode.carryout &&
      this.isCartNotEmpty()
    ) {
      this.modalService.open(this.switchLocationConfirmationModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      });
    } else {
      await this.navigateToMenuPageForNewDeliveryAddress();
    }
  }

  async navigateToMenuPageForNewDeliveryAddress() {
    if (this.selectedDeliveryAddress && !this.showSecondaryAddressError) {
      this.deliveryLocation = await this.fetchDeliveryLocation(
        this.selectedDeliveryAddress
      );
      if (!this.deliveryLocation) {
        this.nearbyLocationsList = { locations: [] };
      }
      if (this.deliveryLocation === null) {
        this.analyticsService.logGaEvent({
          event: 'delivery_unavailable'
        });
      } else {
        this.analyticsService.logGaEvent({
          event: 'store_order_now',
          order_method: 'delivery'
        });
        // redirect to location menu page.
        this.goToMenu(
          this.selectedDeliveryAddress,
          this.secondaryAddressSearchText,
          this.deliveryLocation?.locationStatus
        );
      }
    }
  }

  navigateToMenuPage() {
    if (!this.deliveryAddressData.isNew && this.deliveryAddressData.address) {
      this.navigateToMenuForSavedDeliveryAddress(
        this.deliveryAddressData.address
      );
    } else {
      this.navigateToMenuPageForNewDeliveryAddress();
    }
  }

  goToMenu(
    userDeliveryAddressEvent: Omit<
      NgbTypeaheadSelectItemEvent,
      'preventDefault'
    >,
    secondaryAddress?: string | undefined | null,
    locationStatus?: string | undefined | null
  ) {
    const deliveryAddressForActiveStore: SelectedHandoffDeliveryAddress = {
      streetAddress:
        userDeliveryAddressEvent.item?.address?.streetAddress || '',
      country: userDeliveryAddressEvent.item?.address?.countryCode || '',
      latitude: userDeliveryAddressEvent.item?.latitude || 0,
      longitude: userDeliveryAddressEvent.item?.longitude || 0,
      postalCode: userDeliveryAddressEvent.item?.address?.postalCode || '',
      locality: userDeliveryAddressEvent.item?.address?.locality,
      region: userDeliveryAddressEvent.item?.address?.region,
      secondaryAddress
    };
    const deliveryAddressForActiveStoreStr = JSON.stringify(
      deliveryAddressForActiveStore
    );

    const deliveryAddressForInactiveStore: SelectedHandoffDeliveryAddress = {
      streetAddress:
        userDeliveryAddressEvent.item?.address?.streetAddress || '',
      country: userDeliveryAddressEvent.item?.address?.countryCode || '',
      latitude: userDeliveryAddressEvent.item?.latitude || 0,
      longitude: userDeliveryAddressEvent.item?.longitude || 0,
      postalCode: userDeliveryAddressEvent.item?.address?.postalCode || '',
      city: userDeliveryAddressEvent.item?.address?.locality,
      state: userDeliveryAddressEvent.item?.address?.region
    };
    const deliveryAddressForInactiveStoreStr = JSON.stringify(
      deliveryAddressForInactiveStore
    );
    const deliveryAddressB64 =
      ChooseLocationComponent.STORES_TO_REDIRECT_TO_LEGACY.includes(
        locationStatus || ''
      )
        ? Buffer.from(deliveryAddressForInactiveStoreStr).toString('base64')
        : Buffer.from(deliveryAddressForActiveStoreStr).toString('base64');

    const url = `location/${this.deliveryLocation?.slug}/menu`;
    if (
      ChooseLocationComponent.STORES_TO_REDIRECT_TO_LEGACY.includes(
        locationStatus || ''
      )
    ) {
      // for a delivery order
      const redirectUrl = `${url}?handoffMode=${this.selectedHandoffMode}&deliveryAddress=${deliveryAddressB64}&ngfe_order`;
      this.redirectTo(redirectUrl);
    } else {
      this.router.navigate([`/${url}`], {
        queryParams: {
          handoffMode: this.selectedHandoffMode,
          product: this.productRouteParam,
          deliveryAddress: deliveryAddressB64
        },
        state: { fromOrderPage: true }
      });
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen()),
      filter(() => !this.searching)
    );

    const inputFocus$ = this.focus$.pipe(filter(() => !this.searching));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((term) => {
        this.searchText = term;
        if (term && term.length >= 4)
          return from(
            this.performSearch(term).finally(() => (this.searching = false))
          );
        else return of([]);
      }),
      tap(() => (this.searching = false))
    );
  };

  getWidthOfInputQuickSearch() {
    let inputTextWidth = document.getElementById('input-wpr')?.offsetWidth;
    if (inputTextWidth) {
      inputTextWidth = inputTextWidth - 42;
    }
    return inputTextWidth;
  }

  public fetchDeliveryLocation = async (
    item: Omit<NgbTypeaheadSelectItemEvent, 'preventDefault'>
  ) => {
    this.isLoading = true;

    const res =
      await this.searchLocationWorkflowService.getLocationByDeliveryAddress({
        latitude: item.item?.latitude,
        longitude: item.item?.longitude,
        deliveryAddress: item.item?.address
      });
    this.isLoading = false;
    return res;
  };

  public resetSearchData(handoffMode: string) {
    this.searchText = null;
    this.placeholderText = null;
    this.nearbyLocationsList = null;
    this.deliveryLocation = undefined;
    this.selectedHandoffMode = handoffMode;
    this.mapLocations$.next([]);
    this.showPrimaryAddressError = false;
    this.showPrimaryAddressNoSelectedAddressError = false;
  }

  showUseMyLocation(): boolean {
    if (this.isRecentLocationsEnabled) {
      return (
        this.isSearchUsingDeviceLocationEnabled &&
        this.useMyLocationIsSupported &&
        this.selectedTabId === 'nearby' &&
        this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout &&
        (this.nearbyLocationsList === undefined ||
          this.nearbyLocationsList === null ||
          this.nearbyLocationsList?.locations?.length === 0)
      );
    }
    return (
      this.isSearchUsingDeviceLocationEnabled &&
      this.useMyLocationIsSupported &&
      this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout &&
      (this.nearbyLocationsList === undefined ||
        this.nearbyLocationsList === null ||
        this.nearbyLocationsList?.locations?.length === 0)
    );
  }

  showLocationSearchResult(): boolean {
    if (
      this.isRecentLocationsEnabled &&
      this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout
    ) {
      return this.selectedTabId === 'nearby';
    }
    return true;
  }

  showNearbyTab(): boolean {
    return (
      this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout &&
      this.selectedTabId === 'nearby'
    );
  }

  showRecentLocations(): boolean {
    return (
      this.isRecentLocationsEnabled &&
      this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout &&
      this.selectedTabId === 'recent'
    );
  }

  showFavoriteLocationsTab(): boolean {
    return (
      this.isFavoriteLocationsTabEnabled &&
      this.selectedHandoffMode?.toLowerCase() === HandoffMode.carryout &&
      this.selectedTabId === 'favorites'
    );
  }

  useMyCurrentLocation() {
    this.isLoading = true;
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        this.analyticsService.logGaEvent({
          event: 'stores_enable_locations'
        });

        this.analyticsService.logGaEvent({
          event: 'opt_in_location'
        });

        // perform store search with lat/long of position
        this.nearbyLocationsList = await this.fetchStoreLocations(
          position.coords.latitude,
          position.coords.longitude
        );
        this.setLocations(this.nearbyLocationsList);
        this.searchText = 'Current Location';
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        // display error toast or error modal based on error.code
        switch (error.code) {
          case error.PERMISSION_DENIED: {
            // display error modal
            this.analyticsService.logGaEvent({
              event: 'opt_out_location'
            });
            this.modalService.open(this.commonModal, {
              windowClass: 'common-modal',
              centered: true,
              size: 'sm'
            });

            break;
          }
          case error.POSITION_UNAVAILABLE:
            // display error toast
            this.notificationService.showError(
              'Location information is unavailable.'
            );
            break;
          case error.TIMEOUT:
            // display error toast
            this.notificationService.showError(
              'The request to get your location timed out.'
            );
            break;
          default:
            // display error toast
            this.notificationService.showError(
              'An unknown error occurred requesting your location.'
            );
        }
      },
      {
        timeout: 15000,
        enableHighAccuracy: true
      }
    );
  }

  public closeModal() {
    this.modalService.dismissAll();
  }

  public onUnFavoriteLocation(value: {
    isUnfavorited: boolean;
    locationId: string;
  }) {
    const { isUnfavorited, locationId } = value;
    if (isUnfavorited) {
      const updatedLocations = this.favoriteLocationsList?.locations.filter(
        ({ id }) => id !== locationId
      );
      this.favoriteLocationsList = { locations: updatedLocations || [] };
    }
  }

  private setLocations(locations: LocationsResponseData | null) {
    // this.locationsList = locations;
    this.mapLocations$.next(
      locations?.locations.map((loc) => ({
        lng: loc.longitude,
        lat: loc.latitude,
        description: loc.name,
        id: loc.id
      })) ?? []
    );
  }

  private async performSearch(searchTerm: string) {
    return (
      await this.searchLocationWorkflowService.getGeocodePlaces(
        searchTerm,
        this.selectedHandoffMode
      )
    )?.places;
  }

  private async fetchStoreLocations(lat: number, long: number) {
    return await this.searchLocationWorkflowService.getLocationsByCarryout({
      latitude: lat,
      longitude: long,
      radius: 20,
      radiusUnits: 'mi'
    });
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  private subscribeToAuthState(): void {
    const authState$ = this.store
      .select(AuthFeature.selectAuthState)
      .pipe(filter<AuthFeatureState>(Boolean));

    authState$
      .pipe(
        switchMap((state) =>
          state.isAuthenticated
            ? this.userAccountService.getUserAccount()
            : of(null)
        )
      )
      .subscribe((userDetails) => {
        this.userDetails = userDetails;
      });
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfo = state.storeInfo;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
      })
    );
  }

  private getParamsFromRoute(): void {
    this.productRouteParam =
      this.route.snapshot.queryParamMap.get('product') ??
      this.route.snapshot.paramMap.get('product');
    this.selectedHandoffMode =
      this.route.snapshot.queryParamMap.get('handoffMode') ??
      this.route.snapshot.url[1]?.toString() ??
      'carryout';
    this.searchText = decodeURIComponent(
      this.route.snapshot.queryParamMap.get('searchQuery') ?? ''
    );
  }

  private async fetchRecentLocations() {
    if (this.userDetails) {
      this.locationSearching = true;
      const recentLocationIds: string[] =
        ((await this.userAccountService.getOrderHistory())
          ?.sort((o1, o2) => {
            const d1 = moment(o1.orderDate);
            const d2 = moment(o2.orderDate);
            return d1.isSame(d2) ? 0 : d1.isAfter(d2) ? -1 : 1;
          })
          ?.map((o) => o.location?.id)
          ?.filter(Boolean) as string[]) ?? [];
      const uniqueRecentLocationIds = [...new Set(recentLocationIds)];
      const tenUniqueRecentLocationIds = uniqueRecentLocationIds.slice(0, 10);

      this.recentLocationsList =
        await this.searchLocationWorkflowService.getLocationsByIds({
          locationIds: tenUniqueRecentLocationIds
        });
      this.locationSearching = false;
    } else {
      this.recentLocationsList = { locations: [] };
    }
    this.setLocations(this.recentLocationsList);
  }

  private async fetchFavoriteLocations() {
    if (this.userDetails) {
      this.locationSearching = true;
      this.userDetails = await this.userAccountService.getUserAccount();

      const favoriteWingstopLocations =
        this.userDetails?.favoriteWingstopLocations;
      if (favoriteWingstopLocations && favoriteWingstopLocations.length > 0) {
        const favoriteWingstopLocationIds: string[] =
          (favoriteWingstopLocations
            .map((o) => o?.id)
            .filter(Boolean) as string[]) ?? [];
        const uniqueFavoriteLocationIds = [
          ...new Set(favoriteWingstopLocationIds)
        ];

        const unsortedFavoriteLocationsList =
          await this.searchLocationWorkflowService.getLocationsByIds({
            locationIds: uniqueFavoriteLocationIds
          });
        this.favoriteLocationsList = {
          ...unsortedFavoriteLocationsList,
          locations:
            unsortedFavoriteLocationsList?.locations.sort((o1, o2) =>
              o1.name.localeCompare(o2.name)
            ) || [] // sort by location.ame
        };
      } else {
        this.favoriteLocationsList = { locations: [] };
      }
      this.locationSearching = false;
    } else {
      this.favoriteLocationsList = { locations: [] };
    }
    this.setLocations(this.favoriteLocationsList);
  }

  validateField = (e: {
    target: { value: string };
    preventDefault: () => void;
  }) => {
    if (
      e.target.value !== '' &&
      !e.target.value.match(RegexPatterns.apartmentBuilding)
    ) {
      this.showSecondaryAddressError = true;
    } else {
      this.showSecondaryAddressError = false;
    }
  };
}
